import React from 'react';
import { withSubtheme } from '../../../../../../StarberryComponentsMui';

import ComponentLayout from '../../../../ComponentLayout';
import SwitchActions from '../../../../actions/switchActions';
import defaults from '../../../../actions/defaults';
import { usePropertyActionState } from '../../../../../../services';
import { StarberryIcons } from '../../../../icons';

const ActionComponent = withSubtheme((props) => {

    const {
        className,
        theme,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        _data,
        data,
        // showActionButton,
        role,
        image,
        ...rest
    } = props;

    const useData = data ? data : _data || [];
    let showActionButton;
    let icon;
    let handleClick;

    const handlePerformance = () => {
        window.location.hash = '#performance';
    };
    const handleViewing = () => {
        window.location.hash = '#appointments';
    };
    const handleOffers = () => {
        window.location.hash = '#offers';
    };

    return(
        <ComponentLayout
            className={className}
            theme={theme}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            {useData.map(action => {
                switch (action.action) {
                    case 'offer':
                        showActionButton = true;
                        handleClick= handleOffers
                        icon = <StarberryIcons iconName={`offerIcon`} />
                        break;
                    case 'notes_of_interest':
                        showActionButton = true;
                        handleClick= handleOffers
                        icon = <StarberryIcons iconName={`viewingIcon`} />
                        break;
                    case 'buyers_match':
                        showActionButton = true;
                        handleClick= handlePerformance
                        icon = <StarberryIcons iconName={`profileIcon`} />
                        break;
                    case 'viewings':
                        showActionButton = true;
                        handleClick= handleViewing
                        icon = <StarberryIcons iconName={`viewingIcon`} />
                        break;
                    case 'page_views':
                        showActionButton = true;
                        handleClick= handlePerformance
                        icon = <StarberryIcons iconName={`newsIcon`} />
                        break;
                    default:
                        showActionButton = false
                        icon = <StarberryIcons iconName={``} />
                        handleClick=``
                }

                let data = {...action, showActionButton:showActionButton, action: 'cards'}

                return(
                    <SwitchActions
                        key={action.id}
                        type={`cards`}
                        handleClickOpen={handleClick}
                        theme={theme}
                        data={data}
                        icon={icon}
                        {...rest}
                    />
                )
            })}
        </ComponentLayout>
    )
}, 'actionCardContainer', defaults)


const OfferContent = (props) => {
    const { state } = usePropertyActionState();
    const statsData = props.property.stats || [];
    const noRecord = (!state.loading && !statsData.length);

    if (noRecord) {
        return (<></>)
    }

    return (
        <>
            {!!statsData.length && (
                <ActionComponent
                    title={`Stats`}
                    data={statsData}
                />
            )}
        </>
    )
}

export default OfferContent;
