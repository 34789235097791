import React from "react";
import { withSubtheme } from '../../../../../StarberryComponentsMui';
import GlobalForm from "../../../forms/";
import { collectFormValues, validateFormFields } from "../../../forms/utils";
import { useFormState } from "../../../../../services";
import reportFaultFields from "../../../forms/Forms/reportFault";
import { trackReportFault, trackReportFaultIframe, getUserRole } from "../../../helper/eventTracking";
import { pickBy, identity } from "lodash"

import defaults from "./dialogChildrenDefaults";

const DialogChildren = withSubtheme((props) => {
    const { className } = props
    const property = props?.property || {}
    const tenant_details = props?.tenant_details || {}

    const { state, services } = useFormState()
    let customFields = [...reportFaultFields];

    const [formFields, setFormFields ] = React.useState([...customFields]);

    const ref = React.useRef(null)
    const formStateType = {}
    const messageDialog = { message:state.message, severity:state.error ? 'error' : 'success'}
    const hideForm = (state.success && !state.error)
    const formData = { submitLabel: "Submit" }

    const onSubmit = event => {
        event.preventDefault()

        const formWithValue = validateFormFields(ref, customFields)
        setFormFields(formWithValue);
        // inputRef
        if (ref.current.checkValidity() !== false) {
            let params = {
                is_owner: props.is_owner,
                property_id: property.crm_id,
                negotiator_id: property.negotiator_id,
                form_name: "report_fault",
                reporter: "tenant",
                status: "raised",
                tenancy_id: props.current_tenancy_id,
                // step: step,
                successMessage: "Fault report posted successfully",
                // ...actionParams.formParams,
                trackData: () => {
                    trackReportFault({
                        eventLabel: `Create - Property - ${property?.crm_id}`,
                        eventRole: getUserRole(property)
                    })
                }
            }

            if (props.is_owner)
                params.reporter = "landlord"

            const values = collectFormValues(ref, params)
            services.postForm(values)
        } else {
            // @todo
        }
    }

    // If we have fixflo use that
    if ((!!props.fixflo)) {
        const { buildingNumber, street_name, area, town, postcode, address1, house_name } = property?.address || {}
        // Ref: https://help.fixflo.com/support/solutions/articles/61000268705
        let iframeParams = {
            "address.addressline1": (`${buildingNumber || ''} ${street_name || ''} ${house_name || ''}`).trim(),
            "address.addressline2": area || address1 || '',
            "address.town": town || '',
            "address.postcode": postcode || '',
            "title": tenant_details?.title,
            "firstname": tenant_details?.forename,
            "surname": tenant_details?.surname,
            "email": tenant_details?.email,
            "contactno": tenant_details?.mobilePhone,
            "callbackid": '',
            "externalPropertyReference": property?.crm_id
        }
        iframeParams = pickBy(iframeParams, identity);
        let queryString = '';
        Object.keys(iframeParams).map(key => {
            if (queryString)
                queryString += '&'
            queryString += `${key}=${iframeParams[key]}`
        })
        const url = `${props.fixflo.url}?${encodeURI(queryString)}`;

        // if it is fixflow track open
        trackReportFaultIframe({
            eventLabel: `Open - Property - ${property?.crm_id}`,
            eventRole: getUserRole(property)
        })
        return (
            <div className={className}>
                <div dangerouslySetInnerHTML={{ __html: `<iframe width="100%" height="500px" src=${url} />`}} />
            </div>
        )
    }

    // else us the default form
    return(
        <div className={className}>
            <GlobalForm
                formFields={formFields}
                handleSubmit={onSubmit}
                formStateType={formStateType}
                formState={state}
                messageDialog={messageDialog}
                data={formData}
                ref={ref}
                hideForm={hideForm}
            />
        </div>
    )
}, 'dialogChildren', defaults)

export default DialogChildren
