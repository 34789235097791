import React, { memo } from 'react';
import NoActions from "../../../../progress/NoActions";
import Action from '../../../../actions/action/worksorder';
import { FormStoreProvider } from "../../../../../../services";
import { ComponentWithPagination } from "../../../../pagination"

const ListItem = ({data, image, ...rest}) => {
    return(
        <>
            {data.map((item) => {
                return (
                    <div id={item.id} key={item.id}>
                        <FormStoreProvider>
                            <Action data={{...item, image:image}} {...rest} />
                        </FormStoreProvider>
                    </div>
                )
            })}
        </>
    )
}

const List = ({noRecord, data, title, ...rest}) => {
    const noResult = noRecord || !data.length;
    
    return(
        <>
            {(noResult) && (
                    <NoActions
                        title={`There are no open items`}
                        icon="faultsIcon"
                    />
                )}
                {!noResult && (
                <ComponentWithPagination
                    {...rest}
                    component={ListItem}
                    data={data}
                    marginTop={25}
                    // useLoadMore={false}
                    // pageSize={1}
                />
            )}
        </>
    )
}

export default memo(List);