export default {
    "props": {
        "tabsPropsDesktop": {
            "orientation": "vertical",
            "variant": "scrollable",
        },
        "tabsPropsMobile": {
            "orientation": "horizontal",
            "variant": "scrollable",
            "scrollButtons": "auto",
        },
        "tabProps": {
        },
        "showClientPerf": true,
        "showFacebookPerf": true,
        "showAspcPerf": true,
        "showEspcPerf": true,
        "tabLabelTitle": {
        },
        "tabLabelText": {
        },
    },
    "sx": (theme) => ({
        "flexGrow": 1,
        // "backgroundColor": theme.palette.background.paper,
        "display": "flex",
        "flexDirection": "column",
        [theme.breakpoints.up('lg')]: {
            "flexDirection": "row",
            "height": 600,
        },
        "& .performance-tabs": {
            [theme.breakpoints.up('lg')]: {
                "borderRight": `1px solid ${theme.palette.divider}`,
                "width": "160px",
                "minWidth": "220px",
            },
            "& .performance-tab": {
                "textTransform": "none",
                "opacity": 0.4,
                "padding": theme.spacing(4,2),
                "&.performance-tab-selected": {
                    "opacity": 1,
                },
                "&.performance-tab-labelIcon": {
                    "& svg": {
                        "width": "auto",
                        "height": "24px",
                    },
                },
                "& .label-title": {
                    "fontSize": theme.bodyXSmall,
                    "fontWeight": theme.typography.fontWeightBold,
                    "color": theme.palette.text.primary,
                    [theme.breakpoints.up('lg')]: {
                        "fontSize": theme.bodyLarge,
                    },
                },
                "& .label-text": {
                    "fontSize": theme.bodyXSmall,
                    "lineHeight": theme.lineHeightBXSmall,
                    "color": theme.palette.text.primary,
                    [theme.breakpoints.up('lg')]: {
                        "fontSize": theme.bodySmall,
                        "lineHeight": theme.lineHeightBSmall,
                    },
                },
                "& .tabLogoClient": {
                    "margin": 0,
                    "height": "auto",
                },
            },
        },
        "& .performance-tabs-indicator": {
            "height": "3px",
            [theme.breakpoints.up('lg')]: {
                "width": "3px",
            },
        },
        "& .performance-tabPanel": {
            "flexGrow": 1,
        },
    }),
}
