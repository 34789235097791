import * as React from "react"
import { withSubtheme } from '../../../StarberryComponentsMui'
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import NoActions from "../progress/NoActions";
import { formatPrice } from '../helper/action';
import { calendarDate } from "../helper/dateformat"

import defaults from './defaults'

const Payment = (props) => {
    const {
        date,
        title,
        type,
        value,
        outstanding_amount,
        containerProps,
        innerContainerProps,
        gridItemLeftProps,
        gridItemRightProps,
        dateProps,
        titleProps,
        typeProps,
        valueProps,
        outstandingProps,
        gridItemDateProps,
    } = props

    const outstandingText = (outstanding_amount ? `Outstanding - ${formatPrice(outstanding_amount)}` : `No Outstanding`)
    return(
        <>
            <Grid {...containerProps}>
                <Grid {...gridItemDateProps}>
                    {date && <Typography {...dateProps} classes={{root: "payments-date"}}>{calendarDate(date)}</Typography>}
                </Grid>
                <Grid {...innerContainerProps}>
                    <Grid {...gridItemLeftProps}>
                        {title && <Typography {...titleProps} classes={{root: "payments-title"}}>{title}</Typography>}
                        {type && <Typography {...typeProps} classes={{root: "payments-type"}}>{type}</Typography>}
                    </Grid>
                    <Grid {...gridItemRightProps}>
                        {value && <Typography {...valueProps} classes={{root: "payments-value"}}>{formatPrice(value)}</Typography>}
                        {<Typography {...outstandingProps} classes={{root: "payments-outstanding"}}>{outstandingText}</Typography>}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}


const Payments = withSubtheme((props) => {
    const {
        className,
        allOutstandingProps,
        dateProps,
        titleProps,
        typeProps,
        valueProps,
        outstandingProps,
        containerProps,
        innerContainerProps,
        gridItemLeftProps,
        gridItemRightProps,
        gridItemDateProps,
        totalOutstandingAmount,
        _data,
        data
    } = props

    const mainData = data ? data : _data

    if (!mainData.length) {
        return (
            <NoActions
                title="There are no Transactions"
                icon="transactionsIcon"
            />
        )
    }

    return(
        <div className={className}>
            {(!!totalOutstandingAmount) && (
                <Typography {...allOutstandingProps} classes={{root: "payments-allOutstanding"}}>Outstanding Payment of {formatPrice(totalOutstandingAmount)}</Typography>
            )}
            {mainData.map(item => (
                <Payment
                    key={item.id}
                    containerProps={containerProps}
                    dateProps={dateProps}
                    titleProps={titleProps}
                    typeProps={typeProps}
                    valueProps={valueProps}
                    outstandingProps={outstandingProps}
                    gridItemDateProps={gridItemDateProps}
                    gridItemLeftProps={gridItemLeftProps}
                    gridItemRightProps={gridItemRightProps}
                    innerContainerProps={innerContainerProps}
                    {...item}
                />
            ))}
        </div>
    )

}, "payments", defaults)

export default Payments
