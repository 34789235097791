export default {
    "props": {

    },
    "sx": {
        "& .globalForm-selectField-wrap": {
            "width": "100%"
        }
    },
}
