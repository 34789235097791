export default {
    "props": {
        // "actionChildrenTitleProps": {
        //     "component": "h6",
        //     "variant": "bodyMedium",
        // },
    },
    "sx": (theme) => ({
        "position": "relative",
    })
}