const canShowNewDocumentBtn = (data) => {
    if (data?.role === 'landlord')
        return true;

    if (data.owner_type === 'lettings' && !data.current_tenancy_id)
        return false;

    return true;
}

const getRole = ( data ) => {
    let role = 'buyer';
    if (data.owner_type === 'lettings') {
        role = 'tenant';
        if (data.is_owner)
            role = 'landlord'
    } else if (data.owner_type === 'sales') {
        if (data.is_owner)
            role = 'seller'
    }

    return role;
}

export {
    canShowNewDocumentBtn,
    getRole
}
