export default {
    "props": {
        "tenantContainerProps": {
            "display": "flex",
            "flexDirection": "row",
        },
        "tenantTextTitleProps": {
            "variant": "bodySmall",
        },
        "tenantTextStatusProps": {
            "variant": "bodySmall",
        },
        "_data": [
            {
                "id": "asdad",
                "title": "Address:",
                "value": "This is property address"
            },
            {
                "id": "sdfs",
                "title": "Rent:",
                "value": "This is property rent"
            },
        ],
    },
    "sx": (theme) => ({
        "& .tenantTextTitle": {
            "fontWeight": theme.typography.fontWeightBold,
            "marginRight": theme.spacing(0.5),
            [theme.breakpoints.up('sm')]: {
                "fontSize": theme.bodyLarge,
            },
        },
        "& .tenantTextStatus": {
            [theme.breakpoints.up('sm')]: {
                "fontSize": theme.bodyLarge,
            },
        },
    }),
}