export default {
    "props": {
        "allOutstandingProps": {
            "component": "p",
            "variant": "bodySmall"
        },
        "containerProps": {
            "container": true,
            "spacing": 0,
            "py": ["0","5px"],
        },
        "innerContainerProps": {
            "container": true,
            "spacing": 0,
            "py": ["7px","10px"],
        },
        "gridItemDateProps": {
            "item": true,
            "xs": 12,
        },
        "gridItemLeftProps": {
            "item": true,
            "xs": 6,
        },
        "gridItemRightProps": {
            "item": true,
            "xs": 6,
            "textAlign": "right",
        },
        "dateProps": {
            "component": "div",
            "variant": "bodyXSmall",
        },
        "titleProps": {
            "variant": "bodySmall",
        },
        "typeProps": {
            "variant": "bodyXSmall",
        },
        "valueProps": {
            "variant": "bodySmall",
        },
        "outstandingProps": {
            "variant": "bodyXSmall",
        },
        "_data": [
            { "id": "9340", "date": "Mon 24 August 2020", "title": "B/O DD. Collection", "type": "Rent", "value": "-£1,000.00", "outstanding_value": "£475.00" },
            { "id": "ad", "date": "Mon 23 August 2020", "title": "B/O DD. Collection", "type": "Rent", "value": "-£1,200.00" },
            { "id": "esf", "date": "Mon 22 August 2020", "title": "B/O DD. Collection", "type": "Rent", "value": "-£1,500.00" },
            { "id": "c4", "date": "Mon 21 August 2020", "title": "B/O DD. Collection", "type": "Rent", "value": "-£1,400.00" },
            { "id": "xv", "date": "Mon 20 August 2020", "title": "B/O DD. Collection", "type": "Rent", "value": "-£1,600.00" },
            { "id": "1x", "date": "Mon 24 August 2020", "title": "B/O DD. Collection", "type": "Rent", "value": "-£1,000.00" },
            { "id": "r2", "date": "Mon 24 August 2020", "title": "B/O DD. Collection", "type": "Rent", "value": "-£1,000.00" },
            { "id": "93440", "date": "Mon 24 August 2020", "title": "B/O DD. Collection", "type": "Rent", "value": "-£1,000.00" },
            { "id": "93450", "date": "Mon 24 August 2020", "title": "B/O DD. Collection", "type": "Rent", "value": "-£1,000.00" },
            { "id": "93470", "date": "Mon 24 August 2020", "title": "B/O DD. Collection", "type": "Rent", "value": "-£1,000.00" },
            { "id": "93480", "date": "Mon 24 August 2020", "title": "B/O DD. Collection", "type": "Rent", "value": "-£1,000.00" },
        ],
        "_totalOutstandingAmount": "240"
    },
    "sx": (theme) => ({
        "& .payments-allOutstanding": {
            "marginBottom": theme.spacing(2.5),
            "fontWeight": theme.typography.fontWeightBold,
            [theme.breakpoints.up('sm')]: {
                "fontSize": theme.bodyLarge,
            },
        },
        "& .payments-date": {
            "background": theme.palette.grey.grey4,
            "fontWeight": theme.typography.fontWeightBold,
            "color": theme.palette.grey.grey2,
            "padding": theme.spacing(1, 1, 0.5, 1),
            [theme.breakpoints.up('sm')]: {
                "fontSize": theme.bodySmall,
            },
        },
        "& .payments-title": {
            "display": "block",
            "fontWeight": theme.typography.fontWeightBold,
            "color": theme.palette.primary.heading,
            [theme.breakpoints.up('sm')]: {
                "fontSize": theme.bodyLarge,
            },
        },
        "& .payments-type": {
            "display": "block",
            "color": theme.palette.grey.grey1,
            [theme.breakpoints.up('sm')]: {
                "fontSize": theme.bodySmall,
            },
        },
        "& .payments-value": {
            "display": "block",
            "fontWeight": theme.typography.fontWeightBold,
            "color": theme.palette.primary.heading,
            [theme.breakpoints.up('sm')]: {
                "fontSize": theme.bodyLarge,
            },
        },
        "& .payments-outstanding": {
            "color": theme.palette.grey.grey1,
            [theme.breakpoints.up('sm')]: {
                "fontSize": theme.bodySmall,
            },
        },
    }),
}
