import React from 'react';
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import range from 'lodash/range';
import ActionSkeletonWithMore from "./actionSkeletonWithMore"

const ActionsSkeletonWithMore = ({ title = false, sx }) => {

    return (
      <Stack direction="column" spacing={3} sx={sx}>
          {title && <Skeleton variant="text" width={100} height={40} />}
          {range(0, 3).map(index => <ActionSkeletonWithMore key={index} />)}
      </Stack>
    );
};

export default ActionsSkeletonWithMore;
