export default {
    "props": {
        "buyer": {
            "tabOfferOfferBtn": true,
            "showAddIcon": true,
        },
        "tenant": {
            "tabOfferOfferBtn": true,
            "showAddIcon": true
        }
    },
    "sx": (theme) => ({
    })
}