import React from 'react';
import range from 'lodash/range';
import Stack from "@mui/material/Stack"
import TitleSkeleton from "../../../../StarberryComponentsMui/components/Skeleton/TitleSkeleton"
import TabContainerSkeleton from "../../../../StarberryComponentsMui/components/Skeleton/TabContainerSkeleton"
import TabSkeleton from "../../../../StarberryComponentsMui/components/Skeleton/TabSkeleton"
import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import AutoHeightSkeleton from "../../../../StarberryComponentsMui/components/Skeleton/AutoHeightSkeleton"
import BodyLargeSkeleton from "../../../../StarberryComponentsMui/components/Skeleton/BodyLargeSkeleton"
import MobileTabContainerSkeleton
    from "../../../../StarberryComponentsMui/components/Skeleton/MobileTabContainerSkeleton"
import Container from "@mui/material/Container"

const mdDown = styles => theme => ({
    [theme.breakpoints.down('md')]: styles,
});

const mdUp = styles => theme => ({
    [theme.breakpoints.up('md')]: styles,
});

const MyPropertySkeleton = ({ tabsCount = 6 }) => (
    <React.Fragment>
        <Container maxWidth="xl">
            <Stack direction="column" spacing={4} sx={mdDown({ display: 'none' })}>
                <TitleSkeleton width="90%" />
                <TabContainerSkeleton>
                    {range(0, tabsCount).map(index => <TabSkeleton key={index} />)}
                </TabContainerSkeleton>
                <div>
                    <Grid container>
                        <Grid item md={8}>
                            <TabContainerSkeleton noBorder>
                                {range(0, 2).map(index => <TabSkeleton key={index} />)}
                            </TabContainerSkeleton>
                            <Stack direction="column" spacing={1} sx={{height: 200}} alignItems="center" justifyContent="center">
                                <Skeleton variant="rectangular" width={35} height={25} />
                                <Skeleton variant="text" width={230} height={25} />
                            </Stack>
                        </Grid>
                        <Grid item md={4}>
                            <Stack direction="column" spacing={4}>
                                <AutoHeightSkeleton variant="rectangular" width="100%" aspectRatio={9/16} />
                                <BodyLargeSkeleton width={100} />
                            </Stack>
                        </Grid>
                    </Grid>
                </div>
            </Stack>
        </Container>
        <Stack direction="column" sx={mdUp({ display: 'none' })}>
            <Skeleton width="100%" variant="rectangular" height={40} />
            <AutoHeightSkeleton width="100%" aspectRatio={9/16} />
            <MobileTabContainerSkeleton center>
                <TabSkeleton />
                <TabSkeleton />
            </MobileTabContainerSkeleton>
            <Stack direction="column" spacing={1} pt={4} pl={2}>
                <Skeleton variant="text" width={100} />
                <Skeleton variant="text" width={50} />
                <Skeleton variant="text" width={25} />
            </Stack>
        </Stack>
    </React.Fragment>
);

export default MyPropertySkeleton;
