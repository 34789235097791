export default {
    "props":{
        "tabsProps": {
            "orientation": "vertical",
            "variant": "fullWidth",
        },
        "tabProps": {
            "disableRipple": true,
            "wrapped": true,
        },
        "btnIconProps": {
            "color": "inherit"
        },
    },
    "sx": (theme) => ({
        "& .menuListIconsTabs": {
            "padding": 0,
            "margin": 0,
            // [theme.breakpoints.up('sm')]: {
            //     "marginLeft": `-${theme.gridItemPtablet}`,
            //     "marginRight": `-${theme.gridItemPtablet}`,
            // },
            // [theme.breakpoints.up('md')]: {
            //     "marginLeft": 0,
            //     "marginRight": 0,
            // },
        },
        "& .menuListIconsTabs-vertical": {
        },
        "& .menuListIconsTabs-indicator": {
            "display": "none",
        },
        "& .menuListIconsTabs-tab": {
            "fontWeight": theme.typography.fontWeightBold,
            "textTransform": "none",
            "minHeight": "58px",
            "borderBottom": `1px solid ${theme.palette.divider}`,
            "paddingTop": theme.spacing(1.5),
            "paddingBottom": theme.spacing(1.5),
            "paddingLeft": theme.gridItemPmobile,
            "paddingRight": theme.gridItemPmobile,
            "opacity": 1,
            [theme.breakpoints.up('sm')]: {
                "fontSize": theme.bodyMedium,
                "lineHeight": theme.lineHeightBMedium,
                "paddingLeft": theme.gridItemPtablet,
                "paddingRight": theme.gridItemPtablet,
            },
            [theme.breakpoints.up('md')]: {
                "padding": theme.spacing(1.5,0),
            },
            "&.menuListIconsTabs-tab-wrapper": {
                "display": "flex",
                "flexDirection": "row",
                "alignItems": "center",
                "justifyContent": "flex-start",
            },
        },
        "& .menuListIconsTabs-tab-labelIcon svg": {
            "color": theme.palette.text.primary,
            "marginBottom": 0,
            "marginRight": theme.spacing(2.25),
        }
    })
}
