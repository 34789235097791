import React from 'react';
import { usePropertyActionState, useAuthState } from "../../../../../../services";
import ActionsSkeleton from "../../../../actions/actionsSkeleton"
// import Calendar from '../../../../calendar';
// import { /* calendarDate, */getMonth } from '../../../../helper/dateformat';
import ListItem from './list'


// Calendar Action Container wrap for enable loading
const AppointmentsContent = (props) => {

    const { state, services } = usePropertyActionState();
    const { services:authServices } = useAuthState();
    const data = state?.propertyAppointments || [];
    const noRecord = (!state.loading && !data.length);
    const propertyImage = props.property.property_detail?.thumbnail;

    const fetchData = () => {
        services.getPropertyAppointments({property_id:props.property.property_detail.crm_id});
    }

    const reloadDataFun = React.useCallback(() => {
        authServices.getPendingActionsCount();
        fetchData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        fetchData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (state.loading && !data.length) {
        return <ActionsSkeleton title />
    }

    return <ListItem
        title={`Appointments`}
        data={data}
        property={props.property.property_detail}
        reloadDataFun={reloadDataFun}
        image={propertyImage}
        noRecord={noRecord}
    />
}

export default React.memo(AppointmentsContent);
