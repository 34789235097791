export default {
    "props": {
        "progressContainerProps": {
        },
        "progressComponentProps": {
            "variant": "determinate",
            "progressValueContainerProps": {
                "display": "flex",
                "flexDirection": "column",
                "mb": 1,
            },
            "progressValueContainerProgressProps": {
                "order": 1,
                "width": "100%",
                "mr": 1
            },
            "progressValueContainerNumberProps": {
                "display": "none",
                "order": 0,
            },
            "progressValueTypographyProps": {
                "variant": "h3",
                "color": "textPrimary",
            }
        },
        "progressValueContainerProps": {

        },
        "pendingContainerProps": {
            "display": "flex",
            "flexDirection": "row",
        },
        "pendingTextTitleProps": {
            "variant": "bodySmall",
        },
        "pendingTextStatusProps": {
            "variant": "bodySmall",
        },
        "_data": [
            {
                "id": "action1",
                "title": "Too much work required",
                "count": 11,
                "max": 14,
                "color": "#7552B9",
            },
            {
                "id": "action2",
                "title": "Price too high",
                "count": 7,
                "max": 14,
                "color": "#B252B9",
            },
            {
                "id": "action3",
                "title": "Unsuitable location",
                "count": 6,
                "max": 14,
                "color": "#52B992",
            },
            {
                "id": "action4",
                "title": "Offer made on other property",
                "count": 6,
                "max": 10,
                "color": "#B9527B",
            },
            {
                "id": "action5",
                "title": "Possible Interest",
                "count": 5,
                "max": 14,
                "color": "#77B952",
            },
            {
                "id": "action6",
                "title": "Viewing Cancelled",
                "count": 3,
                "max": 12,
                "color": "#B95252",
            },
            {
                "id": "action7",
                "title": "Just not feeling it",
                "count": 1,
                "max": 8,
                "color": "#5296B9",
            },
        ]
    },
    "sx": (theme) => ({
        "& .progressContainer": {
            "width": "100%",
        },
        "& .progressComponent-root": {
            "height": 4,
            "borderRadius": 2,
        },
        "& .progressComponent-colorPrimary": {
            "backgroundColor": theme.palette.grey[theme.palette.mode === 'light' ? theme.palette.grey.grey1 : theme.palette.grey.grey3],
        },
        "& .progressComponent-bar": {
            "borderRadius": 2,
            // "backgroundColor": theme.palette.primary.main,
        },
        "& .progressValueTypography": {
            "marginBottom": theme.spacing(1),
            [theme.breakpoints.up('md')]: {
                "marginBottom": theme.spacing(1.8),
            },
        },
        "& .pendingTextTitle": {
            "marginBottom": theme.spacing(2.5),
            "marginRight": theme.spacing(1),
        },
        "& .pendingTextStatus": {
            "fontWeight": theme.typography.fontWeightBold,
            "marginLeft": "auto",
        },
    }),
    // "subthemes": {
    //     "globalComponentLayout": {
    //         "props": {
    //             "containerProps": {
    //                 "elevation": 2,
    //             },
    //         },
    //         "sx": (theme) => ({
    //             "padding": 100,
    //             "borderRadius": theme.moduleBorderRadius,
    //             [theme.breakpoints.down('sm')]: {
    //                 "boxShadow": "none",
    //             },
    //             [theme.breakpoints.up('sm')]: {
    //                 "padding": theme.modulePadding,
    //             },
    //             "& .globalComponentLayout-titleContainer": {
    //                 "display": "flex",
    //                 "flexDirection": "row",
    //                 "alignItems": "flex-end",
    //                 "minHeight": 56,
    //                 "paddingBottom": theme.spacing(2),
    //                 "color": "red",
    //                 [theme.breakpoints.up('sm')]: {
    //                     "paddingBottom": theme.spacing(3),
    //                 },

    //             },
    //             "& .globalComponentLayout-title": {
    //                 "fontWeight": "bold",
    //                 "color": theme.palette.grey.grey6,
    //                 [theme.breakpoints.up('sm')]: {
    //                     "fontSize": theme.h5,
    //                     "lineHeight": theme.h5LineH,
    //                     "color": theme.palette.text.primary,
    //                 },
    //             },
    //             "& .globalComponentLayout-titleLinkMore": {
    //                 "fontWeight": "bold",
    //                 "color": theme.palette.grey.grey2,
    //                 "marginLeft": "auto",
    //                 [theme.breakpoints.up('sm')]: {
    //                     "fontSize": theme.bodyMedium,
    //                     "lineHeight": theme.lineHeightBMedium,
    //                     "fontWeight": "normal",
    //                     "color": theme.palette.text.primary,
    //                 },
    //             },
    //         }),

    //         // "componentContainer": {
    //         //     "props": {
    //         //         "elevation": 2,
    //         //     },
    //         //     "sx": (theme) => ({
    //         //         "root": {
    //         //             "padding": 0,
    //         //             "borderRadius": theme.moduleBorderRadius,
    //         //             [theme.breakpoints.down('sm')]: {
    //         //                 "boxShadow": "none",
    //         //             },
    //         //             [theme.breakpoints.up('sm')]: {
    //         //                 "padding": theme.modulePadding,
    //         //             },
    //         //         },
    //         //     }),
    //         // },
    //     },
    // },
}
