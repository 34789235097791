import React from 'react';
import { usePropertyActionState } from '../../../../../../services';
import Grid from '@mui/material/Grid';
import FeedbackProgress from '../../../../feedbackProgress';
import Tabs from '../../../../tabs';
import ActionsSkeleton from "../../../../actions/actionsSkeleton"
import range from 'lodash/range';
import Stack from "@mui/material/Stack"
import Skeleton from "@mui/material/Skeleton"
import FeedbackListItem from "./list"

const Content = (props) => {

    const isMobile = props.mobile;

    const { state, services } = usePropertyActionState();

    const feedbackData = state?.propertyFeedbacks?.feedbacks || [];
    const feedbackStats = state?.propertyFeedbacks?.stats || [];
    const feedbackCount = state?.propertyFeedbacks?.total || 0;

    const noRecord = (!state.loading && !feedbackData.length);

    React.useEffect(() => {
        services.getPropertyFeedbacks({property_id:props.property.property_detail.crm_id});
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (state.loading) {
        return (
            <Grid container>
                <Grid item md={8}>
                    <ActionsSkeleton title />
                </Grid>
                <Grid item md={4}>
                    <Stack direction="column" spacing={2}>
                        <Skeleton variant="text" width="60%" height={40} />
                        {range(0, 8).map(index => (
                            <Stack key={index} direction="column" spacing={1}>
                                <Skeleton variant="rectangular" width="100%" height={7} />
                                <Stack direction="row" justifyContent="space-between">
                                    <Skeleton variant="text" width={100} height={15} />
                                    <Skeleton variant="text" width={50} height={15} />
                                </Stack>
                            </Stack>
                        ))}
                    </Stack>
                </Grid>
            </Grid>
        )
    }

    const mobileTabData = [
        {
            "id": "feedbackTab1",
            "name": "Overview",
            "content":
                <FeedbackProgress
                    theme={props.theme}
                    title="Feedback Summary"
                    data={feedbackStats}
                    count={feedbackCount}
                />,
        },
        {
            "id": "feedbackTab2",
            "name": "Comments",
            "content":
                <FeedbackListItem
                    title="Feedback"
                    theme={props.theme}
                    data={feedbackData}
                    timesAgo={false}
                    actionType={`activity`}
                    showActionButton={false}
                    noRecord={noRecord}
                />
        },
    ]

    // getting data
    if (isMobile) {
        return(
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Tabs
                        manageValue="feedbackTab1"
                        data={mobileTabData}
                        fullWidthTabs={true}
                    />
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} lg={8}>
                    {/* <Activities
                        title="Feedback"
                        theme={props.theme}
                        data={feedbackData}
                        timesAgo={false}
                    /> */}
                    <FeedbackListItem
                        title="Feedback"
                        theme={props.theme}
                        data={feedbackData}
                        timesAgo={false}
                        actionType={`activity`}
                        showActionButton={false}
                        noRecord={noRecord}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <FeedbackProgress
                        theme={props.theme}
                        title="Feedback Summary"
                        data={feedbackStats}
                        count={feedbackCount}
                    />
                </Grid>
            </Grid>
        )
    }
}


export default Content;
