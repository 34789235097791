import * as React from 'react';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Logo, withSubtheme } from '../../../../../../StarberryComponentsMui';
import { StarberryIcons } from '../../../../icons';
// import FacebookPerformance from './facebook';
// import ASPCPerformance from './aspc';
import DefaultPerformance from './default';
import { usePropertyActionState } from "../../../../../../services";
import { dateFieldFormat, subtractDate } from '../../../../helper/dateformat';

import defaults from './defaults';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            className="performance-tabPanel"
            {...other}
            >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const PerformanceTabs = withSubtheme((props) => {
    const {
        theme,
        className,
        tabsPropsDesktop,
        tabsPropsMobile,
        tabProps,
        showClientPerf,
        showFacebookPerf,
        showAspcPerf,
        showEspcPerf,
        tabLabelTitle,
        tabLabelText
    } = props

    const property = props?.property?.property_detail || [];
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { state, services } = usePropertyActionState();
    // const [initialData, setInitialData] = React.useState(false)
    const [tabsShow, setTabsShow] = React.useState({
        initialData: false,
        aspc: false,
        facebook: false,
        website: false,
        espc: false
    });
    const [dateFilters, setDateFilters] = React.useState({fromDate:subtractDate(new Date(), 7), toDate:dateFieldFormat(new Date())});

    if (!isEmpty(state.propertyStats) && !tabsShow.initialData) {
        setTabsShow({
            initialData: true,
            aspc: !!get(state, 'propertyStats.website.items', []).length,
            facebook: !!get(state, 'propertyStats.facebook.items', []).length,
            website: !!get(state, 'propertyStats.website.items', []).length,
            espc: !!get(state, 'propertyStats.espc.items', []).length
        });
    }

    React.useEffect(() => {
        services.getPropertyStats({...dateFilters, property_id:property.crm_id});
    }, [dateFilters]) // eslint-disable-line react-hooks/exhaustive-deps


    const tabVariant = useMediaQuery(theme.breakpoints.down('lg')) ? tabsPropsMobile : tabsPropsDesktop;
    const tabs = [];

    if (showClientPerf && tabsShow.website) {
        let reportType = 'website';
        const label = <>
            <Typography {...tabLabelTitle} classes={{root: "label-title"}}>{theme.companyName}</Typography>
            <Typography {...tabLabelText} classes={{root: "label-text"}}>{get(state, `propertyStats.${reportType}.title`, '')}</Typography>
        </>;
        tabs.push({
            "icon": <Logo classes={{root: "tabLogoClient"}} />,
            "label": label,
            "a11yPropsNumber": 0,
            "content": <DefaultPerformance property={property} _dateFilters={dateFilters} setDateFilters={setDateFilters} state={{...state}} reportTypeText="Aberdein Properties" reportType={reportType}/>,
        })
    }
    if (showFacebookPerf && tabsShow.facebook) {
        let reportType = 'facebook';
        const label = <>
            <Typography {...tabLabelTitle} classes={{root: "label-title"}}>Facebook</Typography>
            <Typography {...tabLabelText} classes={{root: "label-text"}}>{get(state, `propertyStats.${reportType}.title`, '')}</Typography>
        </>;
        tabs.push({
            "icon": <StarberryIcons iconName="facebookIcon" />,
            "label": label,
            "a11yPropsNumber": 1,
            "content": <DefaultPerformance property={property} _dateFilters={dateFilters} setDateFilters={setDateFilters} state={{...state}}  reportTypeText="Facebook" reportType={reportType} />,
        })
    }
    if (showAspcPerf && tabsShow.aspc) {
        let reportType = 'aspc';
        const label = <>
            <Typography {...tabLabelTitle} classes={{root: "label-title"}}>ASPC</Typography>
            <Typography {...tabLabelText} classes={{root: "label-text"}}>{get(state, `propertyStats.${reportType}.title`, '')}</Typography>
        </>;
        tabs.push({
            // "icon": <StarberryIcons iconName="facebookIcon" />,
            "label": label,
            "a11yPropsNumber": 2,
            "content": <DefaultPerformance property={property} _dateFilters={dateFilters} setDateFilters={setDateFilters} state={{...state}} reportTypeText="ASPC" reportType={reportType} />,
        })
    }
    if (showEspcPerf && tabsShow.espc) {
        let reportType = 'espc';
        const label = <>
            <Typography {...tabLabelTitle} classes={{root: "label-title"}}>ESPC</Typography>
            <Typography {...tabLabelText} classes={{root: "label-text"}}>{get(state, `propertyStats.${reportType}.title`, '')}</Typography>
        </>;
        tabs.push({
            // "icon": <StarberryIcons iconName="facebookIcon" />,
            "label": label,
            "a11yPropsNumber": 3,
            "content": <DefaultPerformance property={property} _dateFilters={dateFilters} setDateFilters={setDateFilters} state={{...state}} reportTypeText="ESPC" reportType={reportType} />,
        })
    }

    return(
        <div className={className}>
            {tabs.length > 0 &&
                <>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="Property Performance"
                        classes={{
                            root: "performance-tabs",
                            indicator: "performance-tabs-indicator",
                        }}
                        {...tabVariant}
                    >
                        {tabs.map((tab, key) => (
                            <Tab
                                key={key}
                                icon={tab?.icon}
                                label={tab.label}
                                {...a11yProps(tab.a11yPropsNumber)}
                                {...tabProps}
                                classes={{
                                    root: "performance-tab",
                                    labelIcon: "performance-tab-labelIcon",
                                    selected: "performance-tab-selected",
                                }}
                            />
                        ))}
                    </Tabs>
                    {tabs.map((tab, key) => (
                        <TabPanel value={value} index={key} key={key}>
                            {tab?.content}
                        </TabPanel>
                    ))}
                </>
            }
        </div>
    )
}, "performanceTabs", defaults);

export default PerformanceTabs;
