import React from "react";
import { withSubtheme } from '../../../../../StarberryComponentsMui';
import Button from "@mui/material/Button";
import ActionModalDefault from '../../../modals/default';
import DialogChildren from './dialogChildren';
import { FormStoreProvider, useFormState, usePropertyActionState } from "../../../../../services";
import defaults from './defaults';
import CircularProgress from '../../../progress/CircularProgress'

const ReportFaultBtnWrap = withSubtheme((props) => {
    const {
        btnLabelProps,
        modalTitle,
        btnLabel,
        btnProps,
        property,
        is_owner,
        current_tenancy_id,
        fixflo,
        tenant_details,
        reloadDataFun,
        maxDialogW,
        hide
    } = props

    const { state, services } = useFormState()
    const { services: actionServices } = usePropertyActionState()

    const label = btnLabel ? btnLabel : btnLabelProps;

    const [open, setOpen] = React.useState(false);
    const [canReloadData, setCanReloadData] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    // if form success then reloadData
    if (state.success && !canReloadData)
        setCanReloadData(true);

    const handleClose = (value) => {
        value.preventDefault();
        services.resetForm();
        if (state?.data?.postAction === 'add') {
            actionServices.updatePropertyStatus({...state.data, key:'worksOrders.open_items'});
        }
        if (reloadDataFun && canReloadData) {
            reloadDataFun();
        }
        setOpen(false);

    };

    let dialogContent = '';
    if (state.loading) {
        dialogContent = <CircularProgress />
    } else {
        dialogContent = <DialogChildren handleClose={handleClose} property={property} is_owner={is_owner} current_tenancy_id={current_tenancy_id} fixflo={fixflo} tenant_details={tenant_details} />
    }

    if (hide)
        return null;

    return(
        <>
            <Button {...btnProps} className="btn report-fault-btn" onClick={handleClickOpen}>{label}</Button>
            <ActionModalDefault
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                children={dialogContent}
                loading={state.loading}
                success={state?.success}
                title={modalTitle ? modalTitle : "Report a New Fault"}
                maxDialogWidth={maxDialogW}
            />
        </>
    )
}, 'reportFaultBtn', defaults)


const ReportFaultBtn = (props) => {
    return (
        <FormStoreProvider>
            <ReportFaultBtnWrap {...props} />
        </FormStoreProvider>
    )
}

export default ReportFaultBtn;
