export default {
    "props": {
        "titleProps": {
            "component": "h2",
            "variant": "h6",
        }
    },
    "sx": (theme) => ({
        "& .performance-title": {
            "marginBottom": theme.spacing(4),
        },
    }),
}