import React from 'react';
import _ from 'lodash';
import { usePropertyActionState } from "../../../../../../services";
import NoActions from "../../../../progress/NoActions";
// import Actions from '../../../../actions';
import Grid from '@mui/material/Grid';
import Payments from '../../../../payments';
import Tabs from '../../../../tabs';
import SingleProperty from '../../../../property/singleProperty';
import _properties from '../../../../../../sample/properties.yaml';
import TabContainerSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/TabContainerSkeleton"
import TabSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/TabSkeleton"
import Stack from "@mui/material/Stack"
import AutoHeightSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/AutoHeightSkeleton"
import BodyLargeSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/BodyLargeSkeleton"
import { ComponentWithPagination } from "../../../../pagination"

const PaymentsComponent = (props) => {

    return (
        <Payments {...props} />
    )
}
const transactionsTabData = ({data}) => {

    const tabData = [];
    let count = 0;
    Object.entries(data.tabs).map(([key, value]) => {
        count++;
        return tabData.push(
            {
                "id": `tab${count}`,
                "name": `${key}`,
                "content": <ComponentWithPagination
                    component={PaymentsComponent}
                    marginTop={20}
                    data={value.items}
                    totalOutstandingAmount={value.total_outstanding_amount}
                />
            }
        )
    });

    return (
        tabData
    )
}
const TabsTransactionsComp = (props) => {
    return(
        <Tabs
            data={transactionsTabData(props)}
            fullWidthTabs={true}
        />
    )
}

const SinglePropertyComp = (props) => {

    let offerText = '';
    if (props.property?.offers && props.property.offers.length) {
        offerText = `${props.property.offers.length} offers received`;
    }

    let property = _properties[0]; // Use default data if the property data is not there

    if (props.property) {
        property = {
            ...props.property,
            offerText: offerText
        }
    }

    return(
        <>
            <SingleProperty
                properties={[property]}
            />
        </>
    )
}

const PaymentsContent = (props) => {
    const isMobile = props.mobile;

    const { state, services } = usePropertyActionState();

    const offerData = state?.propertyTransactions || [];

    const noRecord = (!state.loading && _.isEmpty(offerData)); // @todo: Need to check the data

    React.useEffect(() => {
        services.getPropertyTransactions({property_id:props.property.property_detail.crm_id});
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (state.loading) {
        return (
            <Grid container>
                <Grid item md={8}>
                    <Stack direction="column" spacing={4}>
                        <TabContainerSkeleton noBorder>
                            <TabSkeleton />
                            <TabSkeleton />
                        </TabContainerSkeleton>
                    </Stack>
                </Grid>
                <Grid item md={4}>
                    <Stack direction="column" spacing={4}>
                        <AutoHeightSkeleton width="100" aspectRatio={6/19} variant="rectangular" />
                        <BodyLargeSkeleton width="50%" />
                    </Stack>
                </Grid>
            </Grid>
        )
    }

    if (noRecord) {
        return (
            <NoActions
                title="There are no Transactions"
                icon="transactionsIcon"
            />
        )
    }

    if (isMobile) {
        return(
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <TabsTransactionsComp data={offerData} />
                </Grid>
                {/*<Grid item xs={12}>
                    <MenuListTabsComp
                        title="Property Overview"
                        manageValue={props.manageValue}
                        manageHandleChange={props.manageHandleChange}
                    />
                </Grid>*/}
            </Grid>
        )
    } else {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} lg={8}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TabsTransactionsComp data={offerData} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <SinglePropertyComp
                        theme={props.theme}
                        properties={props.properties}
                        property={props.property?.property_detail}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default PaymentsContent;
