import React from 'react';
import { get } from 'lodash';
import { usePropertyActionState, useAuthState } from "../../../../../../services";
// import NoActions from "../../../../progress/NoActions";
// import Actions from '../../../../actions';
import CreateTenancy from '../../../../PropertyResult/CreateTenancy';
import ActionsSkeletonWithMore from "../../../../actions/actionsSkeletonWithMore"
import ListItem from "./list"

const TenancyContent = (props) => {

    const { state, services } = usePropertyActionState();
    const { services:authServices } = useAuthState();
    const offerData = state?.propertyTenances || [];
    const noRecord = (!state.loading && !offerData.length);
    const actionTitle = props?.offer?.actionTitle || 'Tenancies';
    const propertyImage = props.property.property_detail?.thumbnail;
    const showOfferBtn = get(props, `${props.role || ''}.tabOfferOfferBtn`, false);
    const showAddIcon = get(props, `${props.role || ''}.showAddIcon`, false);

    const fetchData = () => {
        services.getPropertyTenancies({property_id:props.property.property_detail.crm_id});
    }

    const reloadDataFun = React.useCallback(() => {
        authServices.getPendingActionsCount();
        fetchData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        fetchData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (state.loading) {
        return <ActionsSkeletonWithMore title />
    }

    return (
        <>
            {showOfferBtn && (
                <CreateTenancy showAddIcon={showAddIcon} property={props.property.property_detail} reloadDataFun={reloadDataFun} />
            )}

            <ListItem
                noRecord={noRecord}
                data={offerData}
                image={propertyImage}
                title={actionTitle}
                // title="Tenancies"
                reloadDataFun={reloadDataFun}
            />
        </>
    )
}

export default TenancyContent;
