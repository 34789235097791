import React from "react";
import { withSubtheme } from '../../../../../StarberryComponentsMui';
import Button from "@mui/material/Button";
import ActionModalDefault from '../../../modals/default';
import DialogChildren from './dialogChildren';
import { FormStoreProvider, useFormState, usePropertyActionState } from "../../../../../services";
import defaults from './defaults';
import CircularProgress from '../../../progress/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import { getRole } from './utils'

const NewDocumentBtnWrap = withSubtheme((props) => {
    const {
        btnLabelProps,
        modalTitle,
        btnLabel,
        btnProps,
        property,
        config
    } = props

    const persona = getRole(property);
    const personaConfig = config[persona] || {}

    const { state, services } = useFormState()
    const { services:propertyService } = usePropertyActionState();

    const label = btnLabel ? btnLabel : btnLabelProps;

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (event) => {
        event.preventDefault();
        services.resetForm();
        if (property?.owner_type && (property?.owner_type === 'lettings' || property.type === 'renting'))
            propertyService.getTenancyDocuments({property_id:property.property_detail.crm_id, loading:false});
        else
            propertyService.getPropertyDocuments({property_id:property.property_detail.crm_id, loading:false});
        setOpen(false);
    };

    let dialogContent = '';
    if (state.loading) {
        dialogContent = <CircularProgress />
    } else {
        dialogContent = <DialogChildren
            handleClose={handleClose}
            property={property}
            persona={persona}
            personaConfig={personaConfig}
        />
    }

    return(
        <>
            {(personaConfig?.canUpload) && (
                <Button {...btnProps} className="btn report-fault-btn" onClick={handleClickOpen}><AddIcon sx={{ mr: 1 }} />{label}</Button>
            )}
            <ActionModalDefault
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                children={dialogContent}
                loading={state.loading}
                success={state?.success}
                title={modalTitle ? modalTitle : "New Document"}
            />
        </>
    )
}, 'newDocumentBtn', defaults)

const NewDocumentBtn = (props) => {
    return (
        <FormStoreProvider>
            <NewDocumentBtnWrap {...props} />
        </FormStoreProvider>
    )
}

export default React.memo(NewDocumentBtn);
