import * as React from 'react';
import { withSubtheme } from '../../../../StarberryComponentsMui';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Text } from 'recharts';
import moment from 'moment-timezone';

import defaults from './defaults';

const data = [
    {
      date: '2021-08-21',
      impressions: 1160010,
      clicks: "35710",
    },
    {
      date: '2021-08-22',
      impressions: 1160050,
      clicks: "35750",
    },
    {
      date: '2021-08-23',
      impressions: 1160090,
      clicks: "35790",
    },
    {
      date: '2021-08-24',
      impressions: 11600130,
      clicks: "357130",
    }
];

const PerformanceChart = withSubtheme((props) => {
    const {
        className,
        data
    } = props

    const parseData = data.map((item, key) => {
        if (item.hasOwnProperty(props.dataKey)) {
            item[props.dataKey] =  parseInt(item[props.dataKey])
        }
        return item
    })

    // Ref: https://github.com/recharts/recharts/issues/1471
    const labelFormatter = unixTime => {
        return moment(unixTime).format("Do MMM");
    };

    const CustomXAxisTick = props => {
      return <Text {...props}>{labelFormatter(props.payload.value)}</Text>;
    };

    return(
        <div className={className}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    width={500}
                    height={300}
                    data={parseData || data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date"
                    tick={
                        <CustomXAxisTick
                            labelFormatter={labelFormatter}
                        />
                    }
                />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey={props.dataKey} stroke="#82ca9d" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}, "lineChart", defaults);

export default PerformanceChart;
