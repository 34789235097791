export default {
    "props": {
        "hide": false,
        "maxDialogW": "md",
        "btnLabel": "Report a Fault",
        "btnProps": {
            "color": "secondary",
            "variant": "contained"
        }
    }
}
