export default {
    "props": {
        "btnLabel": "New Document",
        "config": {
            buyer: {
                canUpload: false,
                associatedType: "contact"
            },
            seller: {
                canUpload: true,
                associatedType: "property"
            },
            tenant: {
                canUpload: false,
                associatedType: "tenancy"
            },
            landlord: {
                canUpload: true,
                associatedType: "landlord"
            },
        },
        "btnProps": {
            // "color": "secondary",
            "variant": "contained",
            "sx": (theme) => ({
                "width": "100%",
                "marginBottom": theme.spacing(2),
                [theme.breakpoints.up('md')]: {
                    "width": "auto",
                    "minWidth": "300px",
                },
            })
        }
    }
}
